const env = process.env.REACT_APP_ENV || 'development';
let apiUrl;

if (env === 'production') {
    apiUrl = 'https://quiz.europe-en-france.gouv.fr/api';
} else {
    apiUrl = 'https://127.0.0.1:8000/api';
}

const config = {apiUrl};

export default config;
