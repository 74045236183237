import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { postEnd, postIdQuizz, postStart } from '../utils/fetcher';
import allAnimals from '../utils/animals.json';

import { ReactComponent as MapDesktop } from '../assets/img/regions-france-vecto.svg';
import { ReactComponent as MapMobile } from '../assets/img/regions-france-mobile-vecto.svg';

import LOGO1 from '../assets/img/logo-anct.png';
import LOGO2 from '../assets/img/ue-cofinancement.png';
import LOGO3 from '../assets/img/europe-engage.png';

function GamePage() {
    const [message, setMessage] = useState('');
    const [step, setStep] = useState('init');
    const [animals, setAnimals] = useState([]);
    const [animalsFinished, setAnimalsFinished] = useState([]);
    const [animalSelected, setAnimalSelected] = useState(null);
    const [regionSelectedLabel, setRegionSelectedLabel] = useState(null);
    const [regionSelected, setRegionSelected] = useState(null);
    const [score, setScore] = useState(0);
    const [showPopin, setShowPopin] = useState(false);

    var sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipe: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const started = (idQuizz) => {
        if ((localStorage.getItem('uid'))) {
            postStart({ quizz: 'animals' }, { uuid: localStorage.getItem('uid') }, (json) => {
                updateLocalStorage(json, idQuizz);
            });
        } else {
            postStart({ quizz: 'animals' }, { uuid: null }, (json) => {
                updateLocalStorage(json, idQuizz);
            });
        }
    };

    const updateLocalStorage = (json, idQuizz) => {
        localStorage.setItem('id', json.id);
        localStorage.setItem('uid', json.uid);

        postIdQuizz({ 'id': localStorage.getItem('id') }, { 'idQuizz': idQuizz });
    };

    useEffect(() => {
        setAnimals(_getMultipleRandom(allAnimals, 10));
    }, []);

    useEffect(() => {
        const regions = document.getElementById('regions');

        if (regions) {
            regions.childNodes.forEach(function(region) {
                region.addEventListener('click', function(event) {
                    const r = event.target.parentNode.id;

                    setRegionSelected(r);

                    switch (r) {
                        case 'auvergne-rhone-alpes':
                            setRegionSelectedLabel('Auvergne-Rhône-Alpes');
                            break;

                        case 'bourgogne-franche-comte':
                            setRegionSelectedLabel('Bourgogne-Franche-Comté');
                            break;

                        case 'bretagne':
                            setRegionSelectedLabel('Bretagne');
                            break;

                        case 'centre-val-de-loire':
                            setRegionSelectedLabel('Centre-Val de Loire');
                            break;

                        case 'corse':
                            setRegionSelectedLabel('Corse');
                            break;

                        case 'grand-est':
                            setRegionSelectedLabel('Grand Est');
                            break;

                        case 'guadeloupe':
                            setRegionSelectedLabel('Guadeloupe');
                            break;

                        case 'guyanne':
                            setRegionSelectedLabel('Guyane');
                            break;

                        case 'hauts-de-france':
                            setRegionSelectedLabel('Hauts de France');
                            break;

                        case 'ile-de-france':
                            setRegionSelectedLabel('Ile de France');
                            break;

                        case 'martinique':
                            setRegionSelectedLabel('Martinique');
                            break;

                        case 'mayotte':
                            setRegionSelectedLabel('Mayotte');
                            break;

                        case 'normandie':
                            setRegionSelectedLabel('Normandie');
                            break;

                        case 'nouvelle-aquitaine':
                            setRegionSelectedLabel('Nouvelle-Aquitaine');
                            break;

                        case 'occitanie':
                            setRegionSelectedLabel('Occitanie');
                            break;

                        case 'pays-de-la-loire':
                            setRegionSelectedLabel('Pays de la Loire');
                            break;

                        case 'provence-alpes-cotes-azur':
                            setRegionSelectedLabel('Provence-Alpes-Côte d\'Azur');
                            break;

                        case 'reunion':
                            setRegionSelectedLabel('Réunion');
                            break;

                        case 'st-martin':
                            setRegionSelectedLabel('Saint-Martin');
                            break;
                    }
                });
            });
        }
    }, [step]);

    useEffect(() => {
        const regions = document.getElementById('regions');

        if (regions) {
            regions.childNodes.forEach(function(region) {
                const rId = region.id;

                if (rId == regionSelected) {
                    region.classList.add('selected');
                } else {
                    region.classList.remove('selected');
                }
            });
        }
    }, [regionSelectedLabel]);

    useEffect(() => {
        if (showPopin) {
            document.body.classList.add('with-popin');
        } else {
            document.body.classList.remove('with-popin');
        }
    }, [showPopin]);

    const _reset = () => {
        setMessage('');
        setStep('init');
        setAnimals(_getMultipleRandom(allAnimals, 10));
        setAnimalsFinished([]);
        setAnimalSelected(null);
        setRegionSelectedLabel(null);
        setRegionSelected(null);
        setScore(0);
        setShowPopin(false);
    };

    const _getMultipleRandom = (arr, num) => {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());

        return shuffled.slice(0, num);
    };

    const _selectAnimal = (a) => {
        if (!_animalIsFinished(a)) {
            setAnimalSelected(a);
        }
    };

    const _validateRegion = () => {
        if (regionSelectedLabel && animalSelected) {
            if (_hasWon()) {
                setScore(score + 1);
            }

            setAnimalsFinished([
                ...animalsFinished,
                {
                    animal: animalSelected.animal,
                    hasWon: _hasWon()
                }
            ]);

            setShowPopin(true);
        }
    };

    const _hasWon = () => {
        return animalSelected.regions.includes(regionSelectedLabel);
    };

    const _closePopin = () => {
        setAnimalSelected(null);
        setRegionSelectedLabel(null);
        setRegionSelected(null);

        setShowPopin(false);

        if (animals.length == animalsFinished.length) {
            setStep('score');
            postEnd({ 'id': localStorage.getItem('id') });
        }
    };

    const _animalIsSelected = (a) => {
        return animalSelected && animalSelected.animal == a.animal;
    };

    const _animalIsWon = (a) => {
        const filter = animalsFinished.filter((ac) => ac.animal == a.animal && ac.hasWon);

        return filter.length !== 0;
    };

    const _animalIsFail = (a) => {
        const filter = animalsFinished.filter((ac) => ac.animal == a.animal && !ac.hasWon);

        return filter.length !== 0;
    };

    const _animalIsFinished = (a) => {
        const filter = animalsFinished.filter((ac) => ac.animal == a.animal && ac.hasWon != null);

        return filter.length !== 0;
    };

    const starter = () => {
        setStep('game');
        started('animals');
    };

    const { innerWidth: width } = window;

    return (
        <main className={step}>
            {message !== '' && <div className='alert alert-danger text-center p-1 mb-4'>{message}</div>}

            {width < 768 || width >= 1199 ? (
                <>
                    {step === 'init' && (
                        <>
                            <div className='logos'>
                                <img src={LOGO1} alt='Agence nationale cohesion' />
                                <img src={LOGO2} alt='Europe engage' />
                                <img src={LOGO3} alt='UE cofinancement' />
                            </div>

                            <div className='txt1'>Toutes ces espèces menacées vivent en France et ont fait l’objet d’un
                                plan de sauvegarde cofinancé par l’Union européenne.
                            </div>

                            <div className='txt2'>Retrouvez la région dans laquelle chaque animal a été sauvé grâce à
                                l’Europe !
                            </div>

                            <button className='btn btn-primary' onClick={starter}>
                                Démarrer le quiz
                            </button>

                            <div className='txt3'>Ce quiz a été réalisé par l’Agence nationale de la cohésion des
                                territoires avec l’aide des autorités de gestion des fonds européens, des Europe Direct,
                                du ministère de la transition écologique et de la cohésion des territoires et de
                                l’Office français de la biodiversité. Les données sont issues de programmes de
                                sauvegarde d’espèces animales cofinancés par l’Union européenne connus et ne
                                présupposent pas de la présence de ces espèces uniquement dans les régions citées.
                            </div>
                        </>
                    )}

                    {step === 'game' && (
                        <>
                            <div className='logos'>
                                <img src={LOGO1} alt='Agence nationale cohesion' />
                                <img src={LOGO2} alt='Europe engage' />
                                <img src={LOGO3} alt='UE cofinancement' />
                            </div>

                            <div className='txt'>
                                {width >= 1199 ? (
                                    <>
                                        Sélectionnez l’animal dans le carrousel et placez-le
                                        <br />à l’aide de votre souris sur la carte.
                                    </>
                                ) : (
                                    <>
                                        Sélectionnez l’animal dans le carrousel et placez-le
                                        <br />à l’aide de votre doigt sur la carte.
                                    </>
                                )}
                            </div>

                            <div className='map'>{width >= 1199 ? <MapDesktop /> : <MapMobile />}</div>

                            <Slider {...sliderSettings}>
                                {animals.map((a) => (
                                    <div //
                                        key={a.animal}
                                        className={`slider-item${_animalIsSelected(a) ? ' selected' : ''}${_animalIsWon(a) ? ' won' : ''}${_animalIsFail(a) ? ' fail' : ''}`}
                                        onClick={() => _selectAnimal(a)}
                                    >
                                        <div className='label'>{a.animal}</div>

                                        <div className='img-container'>
                                            <img src={`/animals/${a.photo}`} alt={a.animal} />
                                        </div>

                                        <div className='credit'>&copy; {a.creditphoto}</div>
                                    </div>
                                ))}
                            </Slider>

                            {regionSelectedLabel && animalSelected && (
                                <button className='btn btn-primary btn-validate' onClick={_validateRegion}>
                                    Valider
                                </button>
                            )}

                            {showPopin && (
                                <div className='popin'>
                                    <div
                                        className={`popin-content${_animalIsWon(animalSelected) ? ' won' : ''}${_animalIsFail(animalSelected) ? ' fail' : ''}`}>
                                        <div
                                            className='txt1'>{_hasWon() ? 'Bonne réponse !' : 'Mauvaise réponse !'}</div>

                                        <div className='txt2'>{animalSelected.animal}</div>

                                        <div className='txt3'>
                                            Région{animalSelected.regions.length > 1 ? 's' : ''} {animalSelected.regions.join(', ')}
                                        </div>

                                        <div className='txt4'>{animalSelected.resume}</div>

                                        {animalSelected.financement != '' && <div className='txt5'>Financement européen
                                            : {animalSelected.financement}</div>}

                                        <div className='txt6'>
                                            {animalSelected.plus.map((p, index) => (
                                                <span key={index}>
                                                    {index > 0 && <>{', '}</>}

                                                    <a key={index} href={p} target='_blank'>
                                                        En savoir plus
                                                    </a>
                                                </span>
                                            ))}
                                        </div>

                                        <div className='text-center'>
                                            <button className='btn btn-primary close-popin' onClick={_closePopin}>
                                                Fermer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {step === 'score' && (
                        <>
                            <div className='logos'>
                                <img src={LOGO1} alt='Agence nationale cohesion' />
                                <img src={LOGO2} alt='Europe engage' />
                                <img src={LOGO3} alt='UE cofinancement' />
                            </div>

                            <div className='score-player'>
                                <span className='label'>Score : </span>
                                <span className='nb'>{score}/10</span>
                            </div>

                            <div className='txt1'>
                                {score <= 4 && <>Pas mal, vous apprenez petit à petit comment l’Union européenne aide
                                    les régions à protéger les animaux. Tentez une nouvelle fois votre chance !</>}
                                {score >= 5 && score <= 7 && <>Bravo, bien joué ! Bientôt le sans faute. Continuez à
                                    découvrir comment l’Europe s’investit pour protéger les espèces menacées.</>}
                                {score >= 8 && <>Félicitations ! Vous êtes un expert. Vous avez su associer
                                    l’implication de l’Union européenne dans la protection des espèces menacées en
                                    fonction de leur localisation régionale ! Venez découvrir de nouvelles espèces !</>}
                            </div>

                            <div className='txt2'>Merci pour votre participation !</div>

                            <button className='btn btn-primary' onClick={_reset}>
                                Rejouer
                            </button>

                            <div className='txt3'>
                                Pour en savoir plus sur les programmes européens en faveur de la biodiversité animale
                                :{' '}
                                <a href='https://www.europe-en-france.gouv.fr/fr/objectifs-thematiques/environnement'
                                   target='_blank'>
                                    Cliquer-ici
                                </a>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className='logos'>
                        <img src={LOGO1} alt='Agence nationale cohesion' />
                        <img src={LOGO2} alt='Europe engage' />
                        <img src={LOGO3} alt='UE cofinancement' />
                    </div>

                    <div className='txt2'>
                        Pour jouer, merci d’utiliser votre ordinateur ou
                        <br />
                        votre smartphone.
                    </div>
                </>
            )}
        </main>
    );
}

export default GamePage;
