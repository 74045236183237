import axios from 'axios';

import config from './config';

const postStat = (stat, callbackSuccess = null, callbackError = null) => {
    axios.post(`${config.apiUrl}/create-stat`, stat)
        .then(function (json) {
            callbackSuccess && callbackSuccess();
        })
        .catch(function (error) {
            console.error(error);

            callbackError && callbackError();
        });
};

export {postStat};

const postStart = (quizz, uuid, callbackSuccess = null, callbackError = null) => {
    let toSend = {
        ...uuid,
        ...quizz
    };

    axios.post(`${config.apiUrl}/start`, toSend)
        .then(function (json) {
            callbackSuccess && callbackSuccess(json.data);
        })
        .catch(function (error) {
            console.error(error);

            callbackError && callbackError();
        });
};

export {postStart};

const postEnd = (data, callbackSuccess = null, callbackError = null) => {
    axios.post(`${config.apiUrl}/end`, data)
        .then(function (json) {
            callbackSuccess && callbackSuccess(json.data);
        })
        .catch(function (error) {
            console.error(error);

            callbackError && callbackError();
        });
};

export {postEnd};

const postIdQuizz = (id, idQuizz, callbackSuccess = null, callbackError = null) => {
    let toSend = {
        ...id,
        ...idQuizz
    };

    axios.post(`${config.apiUrl}/id_quizz`, toSend)
        .then(function (json) {
            callbackSuccess && callbackSuccess(json.data);
        })
        .catch(function (error) {
            console.error(error);

            callbackError && callbackError();
        });
};

export {postIdQuizz};
