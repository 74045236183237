import ReactDOM from 'react-dom/client';
import React from 'react';

import AllRoutes from './_routes/';

import './assets/scss/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<AllRoutes />);
